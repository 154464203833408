import React, { useState } from 'react';
import './App.css';
import { CssBaseline, Container, Box, Typography, Button } from '@mui/material';

interface EvilLeftist {
  name: string,
  verb: string
}

interface ConservativeVictim {
  name: string, 
  pronoun: string
}

const evilLeftistList : EvilLeftist[]= [
  {name: "AOC", verb: "is"}, 
  {name: "Bill Gates", verb: "is"}, 
  {name: "Joe Biden", verb: "is"},
  {name: "Alec Baldwin", verb: "is"},
  {name: "Bill Clinton", verb: "is"},
  {name: "Al Gore", verb: "is"}, 
  {name: "Hillary Clinton", verb: "is"}, 
  {name: "Dr. Fauci", verb: "is"},   
  {name: "Bernie Sanders", verb: "is"}, 
  {name: "Nancy Pelosi", verb: "is"},
  {name: "George Soros", verb: "is"},
  {name: "Barack Obama", verb: "is"},
  {name: "Kamala Harris", verb: "is"},
  {name: "Anderson Cooper", verb: "is"},
  {name: "Rachel Maddow", verb: "is"},
  {name: "NPR", verb: "is"},
  {name: "The CDC", verb: "is"},
  {name: "Oprah Winfrey", verb: "is"},
  {name: "Whoopi Goldberg", verb: "is"},
  {name: "BLM", verb: "is"},
  {name: "Antifa", verb: "is"},
  {name: "The Illuminati", verb: "are"},
  {name: "The Deep State", verb: "is"},
  {name: "The Hollywood elite", verb: "are"},
  {name: "Scientist", verb: "are"},
  {name: "Feminists", verb: "are"},
  {name: "Hippies", verb: "are"},
  {name: "Big Tech", verb: "is"}, 
  {name: "Greta Thunberg", verb: "is"},
  {name: "RuPaul", verb: "is"}, 
  {name: "Banksy", verb: "is"},
  {name: "Dolly Parton", verb: "is"}
];
const nonSequiturList = [
  "5G", 
  "high-fructose corn syrup", 
  "Gardetto's", 
  "Apple Air Pods", 
  "Amazon Echo", 
  "the McRib", 
  "Tik Tok", 
  "the KFC Double Down", 
  "satellites", 
  "space lasers", 
  "SnapChat", 
  "Facebook", 
  "Twitter", 
  "the internet",
  "wind turbines",
  "vaccines",
  "global warming",
  "fluoride",
  "chem trails",
  "COVID-19",
  "ChatGPT",
  "birds",
  "fake news",
  "pizza restaurants",
  "false accusations",
  "GPS",
  "skewed data",
  "electric vehicles",
  "pornography", 
  "Bud Light",
  "Tide pods",
  "tofu",
  "green M&M's",
  "critical race theory",
  "punk rock", 
  "classified documents",
  "ransomware",
  "books",
  "animal shelters"
];
const negativeVerbList = [
  "curse",  
  "hypnotize", 
  "violate", 
  "radicalize",  
  "brainwash",
  "genderize",
  "corrupt",
  "oppress",
  "cancel",
  "scare",
  "distress",
  "frighten",
  "hurt",
  "injure",
  "reject",
  "torture",
  "groom",
  "undermine",
  "weaken",
  "upset",
  "seduce",
  "sodomize",
  "wrongfully accuse",
  "indict",
  "prosecute",
  "harvest the blood from", 
  "dox"
];
const conservativeVictimList : ConservativeVictim[] = [
  {name: "white children", pronoun: "them"}, 
  {name: "white christian men", pronoun: "them"},
  {name: "white christians", pronoun: "them"}, 
  {name: "youth pastors", pronoun: "them"}, 
  {name: "the Bible", pronoun: "it"}, 
  {name: "Elon Musk", pronoun: "him"}, 
  {name: "unborn children", pronoun: "them"}, 
  {name: "Donald Trump", pronoun: "him"}, 
  {name: "the GOP", pronoun: "them"}, 
  {name: "Ben Shapiro", pronoun: "him"},
  {name: "Marjorie Taylor Greene", pronoun: "her"},
  {name: "Matt Gaetz", pronoun: "him"},
  {name: "Alex Jones", pronoun: "him"},
  // Thinking about removing items like this from the list as they just don't flow well with most of the boogeymen...
  {name: "the 2nd amendment", pronoun: "it"},
  {name: "Tucker Carlson", pronoun: "him"},
  {name: "Lauren Boebert", pronoun: "her"},
  {name: "Donald Trump Jr.", pronoun: "him"},
  {name: "white-nationalists", pronoun: "them"},
  {name: "Glenn Beck", pronoun: "him"},
  {name: "Mitch McConnell", pronoun: "him"}, 
  {name: "Ronald Reagan", pronoun: "him"},
  {name: "Ron DeSantis", pronoun: "him"},
  {name: "flat-earthers", pronoun: "them"},
  {name: "NASCAR fans", pronoun: "them"},
  {name: "straight white men", pronoun: "them"},
  {name: "Ted Cruz", pronoun: "him"},
  {name: "Rand Paul", pronoun: "him"},
  {name: "Mike Pence", pronoun: "him"},
  {name: "Mitt Romney", pronoun: "him"},
  {name: "Charlie Kirk", pronoun: "him"},
  {name: "Sean Hannity", pronoun: "him"},
  {name: "Fox News", pronoun: "it"},
  {name: "Laura Ingraham", pronoun: "her"},
   // Another to consider
  {name: "the NRA", pronoun: "it"},
  // This too
  {name: "the Founding Fathers", pronoun: "them"},
  {name: "veterans", pronoun: "them"},
  {name: "god fearing americans", pronoun: "them"},
  // And this one
  {name: "confederate memorials", pronoun: "them"},
  {name: "Vladimir Putin", pronoun: "him"},
  // This one too
  {name: "a MAGA rally", pronoun: "it"},
  {name: "the South", pronoun: "it"}, 
  {name: "frogs", pronoun: "them"}
];
const conservativeBoogeymanList = [
  "gay", 
  "communist", 
  "socialist",
  "transgender",
  "woke",
  "Jewish",
  "queer",
  "atheist",
  "satanic",
  "worship the devil",
  "have cancer",
  "go to therapy",
  "trust science",
  "believe women",
  "non-binary",  
  "anti-facist",
  "black",
  "hispanic",
  "pay taxes",
  "vegan",
  "cucked",
  "hate god",
  "hate freedom",
  "a banana republic one-party dictatorship",
  "switch genders",
  "a minority",
  "college educated",
  "go on welfare"
];

function App() {

  const [evilLeftist, setEvilLeftist] = useState<EvilLeftist>({name: "[Evil leftist]", verb: "is"});
  const [nonSequitur, setNonSequitur] = useState("[absurd non-sequitur]");
  const [negativeVerb, setNegativeVerb] = useState("[negative verb]");
  const [conservativeVictim, setConservativeVictim] = useState<ConservativeVictim>({name: "[conservative victim]", pronoun: "them"});
  const [conservativeBoogeyman, setConservativeBoogeyman] = useState("[conservative boogeyman]");

  const randomizeListItem = (list: string[]) => {
    return list[Math.floor(Math.random() * list.length)];
  }

  const randomizeEvilLeftistListItem = (list: EvilLeftist[]) => {
    return list[Math.floor(Math.random() * list.length)];
  }

  const randomizeConservativeVictimListItem = (list: ConservativeVictim[]) => {
    return list[Math.floor(Math.random() * list.length)];
  }

  const handleClick = () => {
    setEvilLeftist(randomizeEvilLeftistListItem(evilLeftistList));
    setNonSequitur(randomizeListItem(nonSequiturList));
    setNegativeVerb(randomizeListItem(negativeVerbList));
    setConservativeVictim(randomizeConservativeVictimListItem(conservativeVictimList));
    setConservativeBoogeyman(randomizeListItem(conservativeBoogeymanList));
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }}>
          <Typography sx={{paddingTop: '25px'}} align="center" variant="h2" gutterBottom>
            InfoWars Headline Generator
          </Typography>
          <Typography align='center' variant="body1" gutterBottom>
            {evilLeftist.name} {evilLeftist.verb} using {nonSequitur} to {negativeVerb} {conservativeVictim.name} 
            <br/> making {conservativeVictim.pronoun} {conservativeBoogeyman}.
            <br/>
            <br/>
            <Button onClick={handleClick} variant="contained">Generate</Button>
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default App;
